import React from "react";
import ReactDOM from "react-dom";
// import { Trans } from 'gatsby-plugin-react-i18next';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup} from 'pure-react-carousel'
import Trans from '../../components/Trans'

const TestimonialsEn = () => {
    return (
<div>
    <h2 className="bannerSectionTitle-2-center text-gray-250">Testimonials</h2>
    <h2 className="section-title mb-14 md:mb-28">
        <Trans>Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!</Trans>
    </h2>
    <CarouselProvider className="max-w-4xl relative mx-auto"
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={14}
    >
        <div className="quote-icon1 relative opacity-40 z-10">
            <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_150/gatsbycms/uploads/2021/05/quotation-mark.svg" alt="quote-icon" />
        </div>
        <Slider className="rounded-lg bg-white shadow-xl z-0 h-SE lg:h-computer">
            <Slide index={0}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Shruthi Bopaiah</div>
                        <div className="mb-5"><Trans>AVP and Head of Internal Communication</Trans> <strong>Infosys</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1624509292/gatsbycms/uploads/2021/06/infosys-testomony.png" alt="Infosys Logo" width="120" height="60"/>
                    </div>
                </div>
            </Slide>
            <Slide index={1}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Aiman Fatima </div>
                        <div className="mb-5"><Trans>Manager – Employee Relations</Trans> <strong>Merilytics</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/merilytics-testomony.png" alt="Merilytics Logo" width="120" height="41"/>
                    </div>
                </div>
            </Slide>
            <Slide index={2}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Roshan Andrews</div>
                        <div className="mb-5"><Trans>HR - Employee Experience & Internal Communications</Trans> <strong>Arvind</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/arvind-testomony.png" alt="Arvind Logo" width="120" height="41"/>
                    </div>
                </div>
            </Slide>
            <Slide index={3}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Nikita Kamtekar</div>
                        <div className="mb-5"><Trans>Internal Communications Manager</Trans> <strong>TATA Realty</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/tatarealty-testomony.png" alt="TATA Realty Logo" width="120" height="49"/>
                    </div>
                </div>
            </Slide>
            <Slide index={4}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Bani Sodhi</div>
                        <div className="mb-5"><Trans>Human Resource</Trans> <strong>The Adecco Group</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/adecco-testomony.png" alt="The Adecco Group Logo" width="120" height="57"/>
                    </div>
                </div>
            </Slide>
            <Slide index={5}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Wipro Advantage (powered by Vantage Circle) was launched last year to provide excusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Samir Gadgil</div>
                        <div className="mb-5"><Trans>Vice President and HR Head</Trans> <strong>Wipro BPS</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1624509293/gatsbycms/uploads/2021/06/wipro-testomony.png" alt="Wipro BPS Logo" width="120" height="74"/>
                    </div>
                </div>
            </Slide>
            <Slide index={6}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Ritesh Joseph</div>
                        <div className="mb-5"><Trans>HR Business Partner</Trans> <strong>iSchoolConnect</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/ischoolconnect-testomony.png" alt="iSchoolConnect Logo" width="140" height="15"/>
                    </div>
                </div>
            </Slide>
            <Slide index={7}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>It's been slightly over a year since we launched our rewards and recognition platform in partnership with Vantage circle. FirstReward is a good online platform which enables us to reward employees across the globe, encourage bottom up, top down and peer to peer appreciation. Moreover, the tool has also enabled us to further build and strengthen our existing rewards and appreciation culture. The team has partnered with us at every stage to rollout enhancements where possible.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="mb-5"><strong>Firstsource Solutions Limited</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/firstsource-testomony.png" alt="Firstsource Logo" width="120" height="41"/>
                    </div>
                </div>
            </Slide>
            <Slide index={8}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Pankaj Gupta</div>
                        <div className="mb-5"><Trans>Associate Manager</Trans> <strong>TA Digital</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/TA-Digital-testimony.png" alt="TA Digital Logo" width="140" height="30"/>
                    </div>
                </div>
            </Slide>
            <Slide index={9}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Shiney Xavier</div>
                        <div className="mb-5"><Trans>Associate Manager- HR</Trans> <strong>VFI SLK</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/VFI-SLK-testimony.png" alt="VFI SLK Logo" width="140" height="42"/>
                    </div>
                </div>
            </Slide>
            <Slide index={10}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Swati Kohli</div>
                        <div className="mb-5"><Trans>Lead HR</Trans> <strong>Escalon Business Services</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/e_trim/gatsbycms/uploads/2021/06/Escalon-testimony.png" alt="Escalon Business Services Logo" width="140" height="37"/>
                    </div>
                </div>
            </Slide>
            <Slide index={11}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        <Trans>Vantage Circle Platform was implemented to  support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power.</Trans>
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Vimmi Mehta</div>
                        <div className="mb-5">Head – R&R & Quality Improvement<br/><strong>The Tata Power Company Limited</strong></div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1624509292/gatsbycms/uploads/2021/10/Tata-Power-Logo.png" alt="Tata Power Logo" width="90" height="20"/>
                    </div>
                </div>
            </Slide>
            <Slide index={12}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                        Building a highly engaged workforce is integral to Tata Motors' vision. One of the important pillars for fostering engagement is to celebrate contributions and milestones. This can be best achieved by real-time recognition and celebration. Vantage Circle has been a wonderful partner as we developed our Digital Rewards & Recognition program - Accolades. We value our collaboration with Team Vantage and look forward to working together on offering great experience to our employees.
                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Nitin Sansare</div>
                        <div className="mb-5">Head – Performance Management, Compensation and Benefits</div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649672569/gatsbycms/uploads/2022/04/CM-with-CA-TB.png" alt="Tata Power Logo" width="120" height="14"/>
                    </div>
                </div>
            </Slide>
            <Slide index={13}>
                <div className="mx-auto py-8 px-6 md:p-20">
                    <p className="mb-10 section-subtitle italic">
                    Implementing Vantage Perks as our employee benefits platform has been a game-changer for our organization. With exclusive offers from leading brands and cashback points on purchases, our employees have found it effortless to utilize the service provided. Moreover, the chosen employee benefits have been customized as per our requirements. Our employees absolutely love it, making them happier and more engaged. We couldn't be more glad for our association with Vantage Perks. I would highly recommend Vantage Perks as it offers a great redemption method and features products aligned with our employees' interests. Taking part in this initiative encourages employees to contribute more to the workplace.                    </p>
                    <div className="client-description text-base md:text-lg">
                        <div className="font-bold">Poorna Sridharan</div>
                        <div className="mb-5">HR Executive, Happiest Minds</div>
                    </div>
                    <div className="testimonial-company-logo">
                        <img loading="lazy" className="mx-auto" src="https://res.cloudinary.com/vantagecircle/image/upload/v1649672569/gatsbycms/uploads/2023/04/image_2023_04_05T04_37_10_939Z.png" alt="Tata Power Logo" width="120" height="14"/>
                    </div>
                </div>
            </Slide>
        </Slider>
        <div className="w-full flex justify-center items-center my-9">
            <ButtonBack className="shadow eb-carousel-btn rounded-full p-4 mx-3">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id= "prev" d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>
            </ButtonBack>
            <ButtonNext className="shadow eb-carousel-btn rounded-full p-4 mx-3">
                <svg className="mx-auto text-purple-200" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path id="next" d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg>
            </ButtonNext>
        </div>
        <div className="w-full flex justify-center items-center">
            <DotGroup className = "mx-auto py-5" id = "dots"/>
        </div>
    </CarouselProvider>
</div>
    )
};


const renderTestimonials=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#testimonials-en")
        if (id != null){
            ReactDOM.render(
                <TestimonialsEn />,
                id
            )
        }
    }
}

setTimeout(renderTestimonials,100)

export default TestimonialsEn;